@import 'src/styles/_variables.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'stream-chat-angular/src/assets/styles/scss/index.scss';

:root {

  body, html, app-root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .str-chat__typing-indicator__users {
    display: none;
  }

  .str-chat__channel-header-title {
    display: none;
  }


  .mat-mdc-form-field-flex{
    color: var(--text-regular);
  }
  .mdc-button__label{
    color: var(--text-regular);
  }

  .mat-mdc-input-element{
    color: black !important;
  }

  .selected-date-class {
    background-color: gray !important;
    color: white !important;
    border-radius: 50% !important;
  }

  .mdc-evolution-chip-set__chips {
    gap: 0.5rem;
  }

  ::ng-deep .str-chat__message-simple-name str-chat__message-sender-name {
    display: none;
  }

  .message-simple-name {
    display: none;
  }
  /* Hides the sender name for the chat messages */
  .str-chat__message .str-chat__message-metadata .str-chat__message-sender-name,
  .str-chat__message .str-chat__custom-message-metadata .str-chat__message-sender-name {
    display: none; /* Hides the sender name */
  }


  .message-sender-name {
    display: none;

  }

  .mat-mdc-select-value {
    display: flex !important;
    padding-top: 0.5rem !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 10px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    display: flex;
    justify-content: center;
  }

  .mdc-form-field > label {
    padding-left: 2rem;
  }

  .mdc-form-field {
    width: 2.5rem;
    display: flex !important;
    align-items: unset !important;
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 1rem;
  }

  mat-icon {
    color: var(--button-primary-foccused-background) !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }

  .mdc-text-field {
    will-change: unset !important;
  }

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: black;
    --mdc-switch-selected-handle-color: black;
    --mdc-switch-selected-hover-state-layer-color: black;
    --mdc-switch-selected-pressed-state-layer-color: black;
    --mdc-switch-selected-focus-handle-color: black;
    --mdc-switch-selected-hover-handle-color: black;
    --mdc-switch-selected-pressed-handle-color: black;
    --mdc-switch-selected-focus-track-color: black;
    --mdc-switch-selected-hover-track-color: black;
    --mdc-switch-selected-pressed-track-color: black;
    --mdc-switch-selected-track-color: black;
    --mat-switch-label-text-color: var(--text-regular);
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    overflow: hidden;
    border-radius: 24px;
  }

  .mdc-tab__text-label {
    color: black !important;
  }

  .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .mdc-tab-indicator__content--underline {
    border-color: black !important;
  }

  .cdk-overlay-pane {
    padding: 1rem !important;
  }

  .mat-mdc-menu-panel {
    // width: 100% !important;
    max-width: none !important;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: var(--size-input-xlarger-radius);
    transform-origin: top center !important;
    transition: transform 0.3s ease-in-out;
  }

  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .mat-mdc-menu-item {
    padding: 1rem;
    display: flex;
  }



  --button-error-disable-background: #{$tokens-colors-light-button-error-disable-background};
  --button-error-disable-border: #{$tokens-colors-light-button-error-disable-border};
  --button-error-disable-icons: #{$tokens-colors-light-button-error-disable-icons};
  --button-error-disable-text: #{$tokens-colors-light-button-error-disable-text};
  --button-error-focused-background: #{$tokens-colors-light-button-error-focused-background};
  --button-error-focused-border: #{$tokens-colors-light-button-error-focused-border};
  --button-error-focused-icons: #{$tokens-colors-light-button-error-focused-icons};
  --button-error-focused-text: #{$tokens-colors-light-button-error-focused-text};
  --button-error-hover-background: #{$tokens-colors-light-button-error-hover-background};
  --button-error-hover-border: #{$tokens-colors-light-button-error-hover-border};
  --button-error-hover-icons: #{$tokens-colors-light-button-error-hover-icons};
  --button-error-hover-text: #{$tokens-colors-light-button-error-hover-text};
  --button-error-pressed-background: #{$tokens-colors-light-button-error-pressed-background};
  --button-error-pressed-border: #{$tokens-colors-light-button-error-pressed-border};
  --button-error-pressed-icons: #{$tokens-colors-light-button-error-pressed-icons};
  --button-error-pressed-text: #{$tokens-colors-light-button-error-pressed-text};
  --button-error-regular-background: #{$tokens-colors-light-button-error-regular-background};
  --button-error-regular-border: #{$tokens-colors-light-button-error-regular-border};
  --button-error-regular-icons: #{$tokens-colors-light-button-error-regular-icons};
  --button-error-regular-text: #{$tokens-colors-light-button-error-regular-text};

  --button-ghost-disable-icons: #{$tokens-colors-light-button-ghost-disable-icons};
  --button-ghost-disable-text: #{$tokens-colors-light-button-ghost-disable-text};
  --button-ghost-focused-icons: #{$tokens-colors-light-button-ghost-focused-icons};
  --button-ghost-focused-text: #{$tokens-colors-light-button-ghost-focused-text};
  --button-ghost-hover-icons: #{$tokens-colors-light-button-ghost-hover-icons};
  --button-ghost-hover-text: #{$tokens-colors-light-button-ghost-hover-text};
  --button-ghost-pressed-icons: #{$tokens-colors-light-button-ghost-pressed-icons};
  --button-ghost-pressed-text: #{$tokens-colors-light-button-ghost-pressed-text};
  --button-ghost-regular-icons: #{$tokens-colors-light-button-ghost-regular-icons};
  --button-ghost-regular-text: #{$tokens-colors-light-button-ghost-regular-text};

  --button-primary-disable-background: #{$tokens-colors-light-button-primary-disable-background};
  --button-primary-disable-border: #{$tokens-colors-light-button-primary-disable-border};
  --button-primary-disable-icons: #{$tokens-colors-light-button-primary-disable-icons};
  --button-primary-disable-text: #{$tokens-colors-light-button-primary-disable-text};
  --button-primary-foccused-background: #{$tokens-colors-light-button-primary-foccused-background};
  --button-primary-foccused-border: #{$tokens-colors-light-button-primary-foccused-border};
  --button-primary-foccused-icons: #{$tokens-colors-light-button-primary-foccused-icons};
  --button-primary-foccused-text: #{$tokens-colors-light-button-primary-foccused-text};
  --button-primary-hover-background: #{$tokens-colors-light-button-primary-hover-background};
  --button-primary-hover-border: #{$tokens-colors-light-button-primary-hover-border};
  --button-primary-hover-icons: #{$tokens-colors-light-button-primary-hover-icons};
  --button-primary-hover-text: #{$tokens-colors-light-button-primary-hover-text};
  --button-primary-pressed-background: #{$tokens-colors-light-button-primary-pressed-background};
  --button-primary-pressed-border: #{$tokens-colors-light-button-primary-pressed-border};
  --button-primary-pressed-icons: #{$tokens-colors-light-button-primary-pressed-icons};
  --button-primary-pressed-text: #{$tokens-colors-light-button-primary-pressed-text};
  --button-primary-regular-background: #{$tokens-colors-light-button-primary-regular-background};
  --button-primary-regular-border: #{$tokens-colors-light-button-primary-regular-border};
  --button-primary-regular-icons: #{$tokens-colors-light-button-primary-regular-icons};
  --button-primary-regular-text: #{$tokens-colors-light-button-primary-regular-text};

  --button-secondary-disable-background: #{$tokens-colors-light-button-secondary-disable-background};
  --button-secondary-disable-border: #{$tokens-colors-light-button-secondary-disable-border};
  --button-secondary-disable-icons: #{$tokens-colors-light-button-secondary-disable-icons};
  --button-secondary-disable-text: #{$tokens-colors-light-button-secondary-disable-text};
  --button-secondary-focused-background: #{$tokens-colors-light-button-secondary-focused-background};
  --button-secondary-focused-border: #{$tokens-colors-light-button-secondary-focused-border};
  --button-secondary-focused-icons: #{$tokens-colors-light-button-secondary-focused-icons};
  --button-secondary-focused-text: #{$tokens-colors-light-button-secondary-focused-text};
  --button-secondary-hover-background: #{$tokens-colors-light-button-secondary-hover-background};
  --button-secondary-hover-border: #{$tokens-colors-light-button-secondary-hover-border};
  --button-secondary-hover-icons: #{$tokens-colors-light-button-secondary-hover-icons};
  --button-secondary-hover-text: #{$tokens-colors-light-button-secondary-hover-text};
  --button-secondary-pressed-background: #{$tokens-colors-light-button-secondary-pressed-background};
  --button-secondary-pressed-border: #{$tokens-colors-light-button-secondary-pressed-border};
  --button-secondary-pressed-icons: #{$tokens-colors-light-button-secondary-pressed-icons};
  --button-secondary-pressed-text: #{$tokens-colors-light-button-secondary-pressed-text};
  --button-secondary-regular-background: #{$tokens-colors-light-button-secondary-regular-background};
  --button-secondary-regular-border: #{$tokens-colors-light-button-secondary-regular-border};
  --button-secondary-regular-icons: #{$tokens-colors-light-button-secondary-regular-icons};
  --button-secondary-regular-text: #{$tokens-colors-light-button-secondary-regular-text};

  --button-success-disable-background: #{$tokens-colors-light-button-success-disable-background};
  --button-success-disable-border: #{$tokens-colors-light-button-success-disable-border};
  --button-success-disable-icons: #{$tokens-colors-light-button-success-disable-icons};
  --button-success-disable-text: #{$tokens-colors-light-button-success-disable-text};
  --button-success-focused-background: #{$tokens-colors-light-button-success-focused-background};
  --button-success-focused-border: #{$tokens-colors-light-button-success-focused-border};
  --button-success-focused-icons: #{$tokens-colors-light-button-success-focused-icons};
  --button-success-focused-text: #{$tokens-colors-light-button-success-focused-text};
  --button-success-hover-background: #{$tokens-colors-light-button-success-hover-background};
  --button-success-hover-border: #{$tokens-colors-light-button-success-hover-border};
  --button-success-hover-icons: #{$tokens-colors-light-button-success-hover-icons};
  --button-success-hover-text: #{$tokens-colors-light-button-success-hover-text};
  --button-success-pressed-background: #{$tokens-colors-light-button-success-pressed-background};
  --button-success-pressed-border: #{$tokens-colors-light-button-success-pressed-border};
  --button-success-pressed-icons: #{$tokens-colors-light-button-success-pressed-icons};
  --button-success-pressed-text: #{$tokens-colors-light-button-success-pressed-text};
  --button-success-regular-background: #{$tokens-colors-light-button-success-regular-background};
  --button-success-regular-border: #{$tokens-colors-light-button-success-regular-border};
  --button-success-regular-icons: #{$tokens-colors-light-button-success-regular-icons};
  --button-success-regular-text: #{$tokens-colors-light-button-success-regular-text};

  --button-warning-disable-background: #{$tokens-colors-light-button-warning-disable-background};
  --button-warning-disable-border: #{$tokens-colors-light-button-warning-disable-border};
  --button-warning-disable-icons: #{$tokens-colors-light-button-warning-disable-icons};
  --button-warning-disable-text: #{$tokens-colors-light-button-warning-disable-text};
  --button-warning-focused-background: #{$tokens-colors-light-button-warning-focused-background};
  --button-warning-focused-border: #{$tokens-colors-light-button-warning-focused-border};
  --button-warning-focused-icons: #{$tokens-colors-light-button-warning-focused-icons};
  --button-warning-focused-text: #{$tokens-colors-light-button-warning-focused-text};
  --button-warning-hover-background: #{$tokens-colors-light-button-warning-hover-background};
  --button-warning-hover-border: #{$tokens-colors-light-button-warning-hover-border};
  --button-warning-hover-icons: #{$tokens-colors-light-button-warning-hover-icons};
  --button-warning-hover-text: #{$tokens-colors-light-button-warning-hover-text};
  --button-warning-pressed-background: #{$tokens-colors-light-button-warning-pressed-background};
  --button-warning-pressed-border: #{$tokens-colors-light-button-warning-pressed-border};
  --button-warning-pressed-icons: #{$tokens-colors-light-button-warning-pressed-icons};
  --button-warning-pressed-text: #{$tokens-colors-light-button-warning-pressed-text};
  --button-warning-regular-background: #{$tokens-colors-light-button-warning-regular-background};
  --button-warning-regular-border: #{$tokens-colors-light-button-warning-regular-border};
  --button-warning-regular-icons: #{$tokens-colors-light-button-warning-regular-icons};
  --button-warning-regular-text: #{$tokens-colors-light-button-warning-regular-text};

  //input
  --input-primary-active-focused-background: #{$tokens-colors-light-input-primary-active-focused-background};
  --input-primary-active-focused-border: #{$tokens-colors-light-input-primary-active-focused-border};
  --input-primary-active-focused-icons: #{$tokens-colors-light-input-primary-active-focused-icons};
  --input-primary-active-focused-label: #{$tokens-colors-light-input-primary-active-focused-label};
  --input-primary-active-focused-placeholder: #{$tokens-colors-light-input-primary-active-focused-placeholder};

  --input-primary-disable-background: #{$tokens-colors-light-input-primary-disable-background};
  --input-primary-disable-border: #{$tokens-colors-light-input-primary-disable-border};
  --input-primary-disable-icon: #{$tokens-colors-light-input-primary-disable-icon};
  --input-primary-disable-label: #{$tokens-colors-light-input-primary-disable-label};
  --input-primary-disable-placeholder: #{$tokens-colors-light-input-primary-disable-placeholder};

  --input-primary-error-background: #{$tokens-colors-light-input-primary-error-background};
  --input-primary-error-border: #{$tokens-colors-light-input-primary-error-border};
  --input-primary-error-icon: #{$tokens-colors-light-input-primary-error-icon};
  --input-primary-error-label: #{$tokens-colors-light-input-primary-error-label};
  --input-primary-error-placeholder: #{$tokens-colors-light-input-primary-error-placeholder};

  --input-primary-filled-background: #{$tokens-colors-light-input-primary-filled-background};
  --input-primary-filled-border: #{$tokens-colors-light-input-primary-filled-border};
  --input-primary-filled-icon: #{$tokens-colors-light-input-primary-filled-icon};
  --input-primary-filled-label: #{$tokens-colors-light-input-primary-filled-label};
  --input-primary-filled-placeholder: #{$tokens-colors-light-input-primary-filled-placeholder};

  --input-primary-hover-background: #{$tokens-colors-light-input-primary-hover-background};
  --input-primary-hover-border: #{$tokens-colors-light-input-primary-hover-border};
  --input-primary-hover-icon: #{$tokens-colors-light-input-primary-hover-icon};
  --input-primary-hover-label: #{$tokens-colors-light-input-primary-hover-label};
  --input-primary-hover-placeholder: #{$tokens-colors-light-input-primary-hover-placeholder};

  --input-primary-regular-background: #{$tokens-colors-light-input-primary-regular-background};
  --input-primary-regular-border: #{$tokens-colors-light-input-primary-regular-border};
  --input-primary-regular-icon: #{$tokens-colors-light-input-primary-regular-icon};
  --input-primary-regular-label: #{$tokens-colors-light-input-primary-regular-label};
  --input-primary-regular-placeholder: #{$tokens-colors-light-input-primary-regular-placeholder};

  --input-primary-success-background: #{$tokens-colors-light-input-primary-success-background};
  --input-primary-success-border: #{$tokens-colors-light-input-primary-success-border};
  --input-primary-success-icon: #{$tokens-colors-light-input-primary-success-icon};
  --input-primary-success-label: #{$tokens-colors-light-input-primary-success-label};
  --input-primary-success-placeholder: #{$tokens-colors-light-input-primary-success-placeholder};

  --text-neutral-hight: #{$tokens-colors-light-common-neutral-hight};

  --header-background: #{$tokens-colors-light-surface-primary};

  --first-communication-card-background: #{$tokens-colors-light-common-neutral-lower};
  --second-communication-card-background: #{$tokens-colors-light-common-success-lower};
  --third-communication-card-background: #{$tokens-colors-light-common-danger-lower};

  --border-color: #{$tokens-colors-light-common-neutral-low};

  --description-color: #{$tokens-colors-light-common-neutral-hight};
  --common-success-low: #{$tokens-colors-light-common-success-low};
  --common-success-lower: #{$tokens-colors-light-common-success-lower};
  --common-neutral-hight: #{$tokens-colors-light-common-neutral-hight};
  --common-neutral-lowest: #{$tokens-colors-light-common-neutral-lowest};
  --common-neutral-hightest: #{$tokens-colors-light-common-neutral-hightest};

  --size-input-larger-padding-v: #{$tokens-screen-size-input-larger-padding-v};
  --size-input-large-padding-v: #{$tokens-screen-size-input-large-padding-v};

  --size-input-large-padding-12: #{$tokens-screen-common-spacing-m-12-px};
  --size-input-large-padding-16: #{$tokens-screen-common-spacing-l-16-px};
  --size-input-larger-padding-h: #{$tokens-screen-size-input-larger-padding-h};
  --size-input-large-padding-h: #{$tokens-screen-size-input-large-padding-h};

  --size-common-larger-padding-h: #{$tokens-screen-common-spacing-xxl-32-px};
  --size-input-larger-right-content-gap: #{$tokens-screen-size-input-larger-right-content-gap};

  --size-input-large-right-content-gap: #{$tokens-screen-size-input-large-right-content-gap};

  // Radius
  --size-input-larger-radius: #{$tokens-screen-size-input-larger-radius};
  --size-input-radius-12: #{$tokens-screen-common-radius-l};
  --size-input-radius-24: #{$tokens-screen-common-radius-xxl};
  --size-input-radius-16: #{$tokens-screen-common-radius-xl};
  --size-input-xlarger-radius: #{$tokens-screen-common-radius-xxxl};


  //font size
  --font-size-14: #{$tokens-font-size-14};
  --font-size-16: #{$tokens-font-size-16};
  --font-size-18: #{$tokens-font-size-18};
  --font-size-20: #{$tokens-font-size-20};
  --font-size-28: #{$tokens-font-size-28};
  --font-size-32: #{$tokens-font-size-32};
  --font-size-64: #{$tokens-font-size-64};
  --font-size-96: #{$tokens-font-size-96};
  --font-size-48: #{$tokens-font-size-48};

  //font
  --font-family-text: "Plus Jakarta Sans";
  --font-family-display: "Plus Jakarta Sans";

  //spacing
  --padding-16: #{$tokens-screen-common-spacing-l-16-px};
  --padding-24: #{$tokens-screen-common-spacing-xl-24-px};
  --padding-32: #{$tokens-screen-common-spacing-xxl-32-px};
  --padding-64: #{$tokens-font-size-64};
  --padding-96: #{$tokens-font-size-96};


  //hint
  --common-warning-medium: #{$tokens-colors-light-common-warning-medium};
  --common-succes-medium: #{$tokens-colors-light-common-success-medium};
  --common-info-medium: #{$tokens-colors-light-common-neutral-highter};
  --common-neutral-medium: #{$tokens-colors-light-common-neutral-medium};
  --common-primary-brand-medium: #{$tokens-colors-light-common-primary-brand-medium};


  --screen-size-input-larger-radius: #{$tokens-screen-size-input-larger-radius};
  --screen-size-input-larger-left-content-gap: #{$tokens-screen-size-input-larger-left-content-gap};
  --screen-size-input-larger-icon-size: #{$tokens-screen-size-input-larger-icon-size};
  --screen-size-input-larger-spacing: #{$tokens-screen-size-input-larger-line-spacing};

  --screen-size-input-large-radius: #{$tokens-screen-size-input-large-radius};
  --screen-size-input-large-left-content-gap: #{$tokens-screen-size-input-large-left-content-gap};
  --screen-size-input-large-icon-size: #{$tokens-screen-size-input-large-icon-size};
  --screen-size-input-large-spacing: #{$tokens-screen-size-input-large-line-spacing};


  --background-surface-corner: #{$tokens-colors-light-common-secondary-brand-lower};
  --background-color: #{$tokens-colors-light-common-primary-brand-lowest};
  --surface-page: #{$tokens-colors-light-common-primary-brand-lowest};
  --common-secondary-brand-lowest: #{$tokens-colors-light-common-secondary-brand-lowest};

  --background-common-blur: #{$tokens-colors-light-common-blue-lower};

  --input-small: #{$tokens-screen-common-spacing-s-8-px};

  --size-button-larger-padding-h: #{$tokens-screen-size-button-larger-padding-h};
  --size-button-larger-padding-v: #{$tokens-screen-size-button-larger-padding-v};
  --input-nav-larger-icon-size: #{$tokens-screen-size-button-larger-icon-size};

  --common-neutral-medium: #{$tokens-colors-light-common-neutral-medium};

  --sizes-button-input-nav-larger-line-height: #{$primitives-spacing-28-px};
  --sizes-button-input-nav-larger-icon-size: #{$primitives-spacing-24-px};

  --sizes-button-input-nav-large-line-height: #{$primitives-spacing-24-px};
  --sizes-button-input-nav-large-icon-size: #{$primitives-spacing-20-px};


  --tabs-primary-larger-radius: #{$tokens-screen-size-button-larger-radius};
  --size-button-larger-gap: #{$tokens-screen-size-button-larger-gap};

  --size-button-large-padding-v: #{$tokens-screen-size-button-large-padding-v };
  --size-button-large-padding-h: #{$tokens-screen-size-button-large-padding-h };
  --tabs-primary-large-radius: #{$tokens-screen-size-button-large-radius};
  --size-button-large-gap: #{$tokens-screen-size-button-large-gap};

  --size-button-medium-padding-v: #{$tokens-screen-size-button-medium-padding-v };
  --size-button-medium-padding-h: #{$tokens-screen-size-button-medium-padding-h };
  --tabs-primary-medium-radius: #{$tokens-screen-size-button-medium-radius};
  --size-button-medium-gap: #{$tokens-screen-size-button-medium-gap};

  --size-button-small-padding-v: #{$tokens-screen-size-button-small-padding-v };
  --size-button-small-padding-h: #{$tokens-screen-size-button-small-padding-h };
  --tabs-primary-small-radius: #{$tokens-screen-size-button-small-radius};
  --size-button-small-gap: #{$tokens-screen-size-button-small-gap};

  //text
  --text-body-color: #{$tokens-colors-light-text-body};
  --text-label-color: #{$tokens-colors-light-text-label};
  --text-text-color: #{$tokens-colors-light-text-text};
  --text-regular: #{$tokens-colors-light-common-primary-brand-hightest};


}

[data-theme='dark'] {
  --button-error-disable-background: #{$tokens-colors-dark-button-error-disable-background};
  --button-error-disable-border: #{$tokens-colors-dark-button-error-disable-border};
  --button-error-disable-icons: #{$tokens-colors-dark-button-error-disable-icons};
  --button-error-disable-text: #{$tokens-colors-dark-button-error-disable-text};
  --button-error-focused-background: #{$tokens-colors-dark-button-error-focused-background};
  --button-error-focused-border: #{$tokens-colors-dark-button-error-focused-border};
  --button-error-focused-icons: #{$tokens-colors-dark-button-error-focused-icons};
  --button-error-focused-text: #{$tokens-colors-dark-button-error-focused-text};
  --button-error-hover-background: #{$tokens-colors-dark-button-error-hover-background};
  --button-error-hover-border: #{$tokens-colors-dark-button-error-hover-border};
  --button-error-hover-icons: #{$tokens-colors-dark-button-error-hover-icons};
  --button-error-hover-text: #{$tokens-colors-dark-button-error-hover-text};
  --button-error-pressed-background: #{$tokens-colors-dark-button-error-pressed-background};
  --button-error-pressed-border: #{$tokens-colors-dark-button-error-pressed-border};
  --button-error-pressed-icons: #{$tokens-colors-dark-button-error-pressed-icons};
  --button-error-pressed-text: #{$tokens-colors-dark-button-error-pressed-text};
  --button-error-regular-background: #{$tokens-colors-dark-button-error-regular-background};
  --button-error-regular-border: #{$tokens-colors-dark-button-error-regular-border};
  --button-error-regular-icons: #{$tokens-colors-dark-button-error-regular-icons};
  --button-error-regular-text: #{$tokens-colors-dark-button-error-regular-text};

  --button-ghost-disable-icons: #{$tokens-colors-dark-button-ghost-disable-icons};
  --button-ghost-disable-text: #{$tokens-colors-dark-button-ghost-disable-text};
  --button-ghost-focused-icons: #{$tokens-colors-dark-button-ghost-focused-icons};
  --button-ghost-focused-text: #{$tokens-colors-dark-button-ghost-focused-text};
  --button-ghost-hover-icons: #{$tokens-colors-dark-button-ghost-hover-icons};
  --button-ghost-hover-text: #{$tokens-colors-dark-button-ghost-hover-text};
  --button-ghost-pressed-icons: #{$tokens-colors-dark-button-ghost-pressed-icons};
  --button-ghost-pressed-text: #{$tokens-colors-dark-button-ghost-pressed-text};
  --button-ghost-regular-icons: #{$tokens-colors-dark-button-ghost-regular-icons};
  --button-ghost-regular-text: #{$tokens-colors-dark-button-ghost-regular-text};

  --button-primary-disable-background: #{$tokens-colors-dark-button-primary-disable-background};
  --button-primary-disable-border: #{$tokens-colors-dark-button-primary-disable-border};
  --button-primary-disable-icons: #{$tokens-colors-dark-button-primary-disable-icons};
  --button-primary-disable-text: #{$tokens-colors-dark-button-primary-disable-text};
  --button-primary-foccused-background: #{$tokens-colors-dark-button-primary-foccused-background};
  --button-primary-foccused-border: #{$tokens-colors-dark-button-primary-foccused-border};
  --button-primary-foccused-icons: #{$tokens-colors-dark-button-primary-foccused-icons};
  --button-primary-foccused-text: #{$tokens-colors-dark-button-primary-foccused-text};
  --button-primary-hover-background: #{$tokens-colors-dark-button-primary-hover-background};
  --button-primary-hover-border: #{$tokens-colors-dark-button-primary-hover-border};
  --button-primary-hover-icons: #{$tokens-colors-dark-button-primary-hover-icons};
  --button-primary-hover-text: #{$tokens-colors-dark-button-primary-hover-text};
  --button-primary-pressed-background: #{$tokens-colors-dark-button-primary-pressed-background};
  --button-primary-pressed-border: #{$tokens-colors-dark-button-primary-pressed-border};
  --button-primary-pressed-icons: #{$tokens-colors-dark-button-primary-pressed-icons};
  --button-primary-pressed-text: #{$tokens-colors-dark-button-primary-pressed-text};
  --button-primary-regular-background: #{$tokens-colors-dark-button-primary-regular-background};
  --button-primary-regular-border: #{$tokens-colors-dark-button-primary-regular-border};
  --button-primary-regular-icons: #{$tokens-colors-dark-button-primary-regular-icons};
  --button-primary-regular-text: #{$tokens-colors-dark-button-primary-regular-text};

  --button-secondary-disable-background: #{$tokens-colors-dark-button-secondary-disable-background};
  --button-secondary-disable-border: #{$tokens-colors-dark-button-secondary-disable-border};
  --button-secondary-disable-icons: #{$tokens-colors-dark-button-secondary-disable-icons};
  --button-secondary-disable-text: #{$tokens-colors-dark-button-secondary-disable-text};
  --button-secondary-focused-background: #{$tokens-colors-dark-button-secondary-focused-background};
  --button-secondary-focused-border: #{$tokens-colors-dark-button-secondary-focused-border};
  --button-secondary-focused-icons: #{$tokens-colors-dark-button-secondary-focused-icons};
  --button-secondary-focused-text: #{$tokens-colors-dark-button-secondary-focused-text};
  --button-secondary-hover-background: #{$tokens-colors-dark-button-secondary-hover-background};
  --button-secondary-hover-border: #{$tokens-colors-dark-button-secondary-hover-border};
  --button-secondary-hover-icons: #{$tokens-colors-dark-button-secondary-hover-icons};
  --button-secondary-hover-text: #{$tokens-colors-dark-button-secondary-hover-text};
  --button-secondary-pressed-background: #{$tokens-colors-dark-button-secondary-pressed-background};
  --button-secondary-pressed-border: #{$tokens-colors-dark-button-secondary-pressed-border};
  --button-secondary-pressed-icons: #{$tokens-colors-dark-button-secondary-pressed-icons};
  --button-secondary-pressed-text: #{$tokens-colors-dark-button-secondary-pressed-text};
  --button-secondary-regular-background: #{$tokens-colors-dark-button-secondary-regular-background};
  --button-secondary-regular-border: #{$tokens-colors-dark-button-secondary-regular-border};
  --button-secondary-regular-icons: #{$tokens-colors-dark-button-secondary-regular-icons};
  --button-secondary-regular-text: #{$tokens-colors-dark-button-secondary-regular-text};

  --button-success-disable-background: #{$tokens-colors-dark-button-success-disable-background};
  --button-success-disable-border: #{$tokens-colors-dark-button-success-disable-border};
  --button-success-disable-icons: #{$tokens-colors-dark-button-success-disable-icons};
  --button-success-disable-text: #{$tokens-colors-dark-button-success-disable-text};
  --button-success-focused-background: #{$tokens-colors-dark-button-success-focused-background};
  --button-success-focused-border: #{$tokens-colors-dark-button-success-focused-border};
  --button-success-focused-icons: #{$tokens-colors-dark-button-success-focused-icons};
  --button-success-focused-text: #{$tokens-colors-dark-button-success-focused-text};
  --button-success-hover-background: #{$tokens-colors-dark-button-success-hover-background};
  --button-success-hover-border: #{$tokens-colors-dark-button-success-hover-border};
  --button-success-hover-icons: #{$tokens-colors-dark-button-success-hover-icons};
  --button-success-hover-text: #{$tokens-colors-dark-button-success-hover-text};
  --button-success-pressed-background: #{$tokens-colors-dark-button-success-pressed-background};
  --button-success-pressed-border: #{$tokens-colors-dark-button-success-pressed-border};
  --button-success-pressed-icons: #{$tokens-colors-dark-button-success-pressed-icons};
  --button-success-pressed-text: #{$tokens-colors-dark-button-success-pressed-text};
  --button-success-regular-background: #{$tokens-colors-dark-button-success-regular-background};
  --button-success-regular-border: #{$tokens-colors-dark-button-success-regular-border};
  --button-success-regular-icons: #{$tokens-colors-dark-button-success-regular-icons};
  --button-success-regular-text: #{$tokens-colors-dark-button-success-regular-text};

  --button-warning-disable-background: #{$tokens-colors-dark-button-warning-disable-background};
  --button-warning-disable-border: #{$tokens-colors-dark-button-warning-disable-border};
  --button-warning-disable-icons: #{$tokens-colors-dark-button-warning-disable-icons};
  --button-warning-disable-text: #{$tokens-colors-dark-button-warning-disable-text};
  --button-warning-focused-background: #{$tokens-colors-dark-button-warning-focused-background};
  --button-warning-focused-border: #{$tokens-colors-dark-button-warning-focused-border};
  --button-warning-focused-icons: #{$tokens-colors-dark-button-warning-focused-icons};
  --button-warning-focused-text: #{$tokens-colors-dark-button-warning-focused-text};
  --button-warning-hover-background: #{$tokens-colors-dark-button-warning-hover-background};
  --button-warning-hover-border: #{$tokens-colors-dark-button-warning-hover-border};
  --button-warning-hover-icons: #{$tokens-colors-dark-button-warning-hover-icons};
  --button-warning-hover-text: #{$tokens-colors-dark-button-warning-hover-text};
  --button-warning-pressed-background: #{$tokens-colors-dark-button-warning-pressed-background};
  --button-warning-pressed-border: #{$tokens-colors-dark-button-warning-pressed-border};
  --button-warning-pressed-icons: #{$tokens-colors-dark-button-warning-pressed-icons};
  --button-warning-pressed-text: #{$tokens-colors-dark-button-warning-pressed-text};
  --button-warning-regular-background: #{$tokens-colors-dark-button-warning-regular-background};
  --button-warning-regular-border: #{$tokens-colors-dark-button-warning-regular-border};
  --button-warning-regular-icons: #{$tokens-colors-dark-button-warning-regular-icons};
  --button-warning-regular-text: #{$tokens-colors-dark-button-warning-regular-text};

  //input
  --input-primary-active-focused-background: #{$tokens-colors-dark-input-primary-active-focused-background};
  --input-primary-active-focused-border: #{$tokens-colors-dark-input-primary-active-focused-border};
  --input-primary-active-focused-icons: #{$tokens-colors-dark-input-primary-active-focused-icons};
  --input-primary-active-focused-label: #{$tokens-colors-dark-input-primary-active-focused-label};
  --input-primary-active-focused-placeholder: #{$tokens-colors-dark-input-primary-active-focused-placeholder};

  --input-primary-disable-background: #{$tokens-colors-dark-input-primary-disable-background};
  --input-primary-disable-border: #{$tokens-colors-dark-input-primary-disable-border};
  --input-primary-disable-icon: #{$tokens-colors-dark-input-primary-disable-icon};
  --input-primary-disable-label: #{$tokens-colors-dark-input-primary-disable-label};
  --input-primary-disable-placeholder: #{$tokens-colors-dark-input-primary-disable-placeholder};

  --input-primary-error-background: #{$tokens-colors-dark-input-primary-error-background};
  --input-primary-error-border: #{$tokens-colors-dark-input-primary-error-border};
  --input-primary-error-icon: #{$tokens-colors-dark-input-primary-error-icon};
  --input-primary-error-label: #{$tokens-colors-dark-input-primary-error-label};
  --input-primary-error-placeholder: #{$tokens-colors-dark-input-primary-error-placeholder};

  --input-primary-filled-background: #{$tokens-colors-dark-input-primary-filled-background};
  --input-primary-filled-border: #{$tokens-colors-dark-input-primary-filled-border};
  --input-primary-filled-icon: #{$tokens-colors-dark-input-primary-filled-icon};
  --input-primary-filled-label: #{$tokens-colors-dark-input-primary-filled-label};
  --input-primary-filled-placeholder: #{$tokens-colors-dark-input-primary-filled-placeholder};

  --input-primary-hover-background: #{$tokens-colors-dark-input-primary-hover-background};
  --input-primary-hover-border: #{$tokens-colors-dark-input-primary-hover-border};
  --input-primary-hover-icon: #{$tokens-colors-dark-input-primary-hover-icon};
  --input-primary-hover-label: #{$tokens-colors-dark-input-primary-hover-label};
  --input-primary-hover-placeholder: #{$tokens-colors-dark-input-primary-hover-placeholder};

  --input-primary-regular-background: #{$tokens-colors-dark-input-primary-regular-background};
  --input-primary-regular-border: #{$tokens-colors-dark-input-primary-regular-border};
  --input-primary-regular-icon: #{$tokens-colors-dark-input-primary-regular-icon};
  --input-primary-regular-label: #{$tokens-colors-dark-input-primary-regular-label};
  --input-primary-regular-placeholder: #{$tokens-colors-dark-input-primary-regular-placeholder};

  --input-primary-success-background: #{$tokens-colors-dark-input-primary-success-background};
  --input-primary-success-border: #{$tokens-colors-dark-input-primary-success-border};
  --input-primary-success-icon: #{$tokens-colors-dark-input-primary-success-icon};
  --input-primary-success-label: #{$tokens-colors-dark-input-primary-success-label};
  --input-primary-success-placeholder: #{$tokens-colors-dark-input-primary-success-placeholder};


  --background-surface-corner: #{$tokens-colors-dark-common-blur-yellow-blur};

  //TODO to change this token is wrong
  --text-regular: #{$tokens-colors-dark-common-primary-brand-hightest};

  --surface-page: #{$tokens-colors-dark-common-primary-brand-lowest};
  --common-secondary-brand-lowest: #{$tokens-colors-dark-common-secondary-brand-lowest};

  --header-background: #{$tokens-colors-dark-surface-primary};
  --first-communication-card-background: #{$tokens-colors-dark-common-neutral-lower};
  --second-communication-card-background: #{$tokens-colors-dark-common-success-lower};
  --third-communication-card-background: #{$tokens-colors-dark-common-danger-lower};

  --description-color: #{$tokens-colors-dark-common-neutral-hight};
  --border-color: #{$tokens-colors-dark-common-neutral-low};

  --common-success-low: #{$tokens-colors-dark-common-success-low};
  --common-success-lower: #{$tokens-colors-dark-common-success-lower};
  --common-neutral-hight: #{$tokens-colors-dark-common-neutral-hight};
  --common-neutral-hightest: #{$tokens-colors-dark-common-neutral-hightest};

  //hint
  --common-warning-medium: #{$tokens-colors-dark-common-warning-medium};
  --common-succes-medium: #{$tokens-colors-dark-common-success-medium};
  --common-info-medium: #{$tokens-colors-dark-common-neutral-highter};
  --common-neutral-medium: #{$tokens-colors-dark-common-neutral-medium};
  --common-primary-brand-medium: #{$tokens-colors-dark-common-primary-brand-medium};
  --common-primary-brand-lowest: #{$tokens-colors-dark-common-primary-brand-lowest};
  --background-color: #{$tokens-colors-light-common-primary-brand-hightest};

  //text
  --text-body-color: #{$tokens-colors-dark-text-body};
  --text-label-color: #{$tokens-colors-dark-text-label};
  --text-text-color: #{$tokens-colors-dark-text-text};
  --background-common-blur: #{$tokens-colors-dark-common-blue-lower};

  --common-neutral-medium: #{$tokens-colors-dark-common-neutral-medium};

  --text-neutral-hight: #{$tokens-colors-dark-common-neutral-hight};
  --common-neutral-lowest: #{$tokens-colors-dark-common-neutral-lowest};


}













