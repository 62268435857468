//primitives colors
$primitives-color-black-100: #efeff0;
$primitives-color-black-200: #e4e4e7;
$primitives-color-black-300: #d4d4d8;
$primitives-color-black-400: #bcbcc2;
$primitives-color-black-50: #f7f7f8;
$primitives-color-black-500: #a1a1aa;
$primitives-color-black-600: #71717a;
$primitives-color-black-700: #3f3f46;
$primitives-color-black-800: #27272a;
$primitives-color-black-900-p: #18181b;
$primitives-color-black-950: #050505;
$primitives-color-blue-100: #dbeafe;
$primitives-color-blue-200: #bfdbfe;
$primitives-color-blue-300: #93c5fd;
$primitives-color-blue-400: #60a5fa;
$primitives-color-blue-50: #eff6ff;
$primitives-color-blue-500: #3b82f6;
$primitives-color-blue-600: #2563eb;
$primitives-color-blue-700: #1d4ed8;
$primitives-color-blue-800: #1e40af;
$primitives-color-blue-900-p: #1e3a8a;
$primitives-color-blue-950: #172554;
$primitives-color-gray-100: #f3f4f6;
$primitives-color-gray-200: #e5e7eb;
$primitives-color-gray-300: #d1d5db;
$primitives-color-gray-400: #9ca3af;
$primitives-color-gray-50: #f9fafb;
$primitives-color-gray-500: #6b7280;
$primitives-color-gray-600: #4b5563;
$primitives-color-gray-700: #374151;
$primitives-color-gray-800: #1f2937;
$primitives-color-gray-900: #111827;
$primitives-color-gray-950: #030712;
$primitives-color-green-100: #d8ffea;
$primitives-color-green-200: #b3ffd6;
$primitives-color-green-300: #78fdb6;
$primitives-color-green-400-p: #27f186;
$primitives-color-green-50: #eefff5;
$primitives-color-green-500: #0cdb6e;
$primitives-color-green-600: #03b658;
$primitives-color-green-700: #078e47;
$primitives-color-green-800: #0b703c;
$primitives-color-green-900: #0b5c34;
$primitives-color-green-950: #00341a;
$primitives-color-orange-100: #fdf0d7;
$primitives-color-orange-200: #fbdead;
$primitives-color-orange-300: #f8c579;
$primitives-color-orange-400: #f4a343;
$primitives-color-orange-50: #fef8ee;
$primitives-color-orange-500: #f18c27;
$primitives-color-orange-600: #e26e14;
$primitives-color-orange-700: #bb5313;
$primitives-color-orange-800: #954217;
$primitives-color-orange-900: #783816;
$primitives-color-orange-950: #411a09;
$primitives-color-purple-purple: #9747ff;
$primitives-color-red-100: #ffdfdf;
$primitives-color-red-200: #ffc4c4;
$primitives-color-red-300: #ff9c9c;
$primitives-color-red-400: #ff6262;
$primitives-color-red-50: #fff1f1;
$primitives-color-red-500: #ff3232;
$primitives-color-red-600-p: #f12727;
$primitives-color-red-700: #ca0b0b;
$primitives-color-red-800: #a70d0d;
$primitives-color-red-900: #8a1212;
$primitives-color-red-950: #4b0404;
$primitives-color-slate-100: #f1f5f9;
$primitives-color-slate-200: #e2e8f0;
$primitives-color-slate-300: #cbd5e1;
$primitives-color-slate-400: #94a3b8;
$primitives-color-slate-50: #f8fafc;
$primitives-color-slate-500: #64748b;
$primitives-color-slate-600: #475569;
$primitives-color-slate-700: #41415E33;
$primitives-color-slate-800: #1e293b;
$primitives-color-slate-900: #0f172a;
$primitives-color-slate-950: #020617;
$primitives-color-white-white: #ffffff;
$primitives-color-yellow-100: #f9fcc5;
$primitives-color-yellow-200-p: #f8fa9f;
$primitives-color-yellow-300: #f5f34f;
$primitives-color-yellow-400: #f0e41f;
$primitives-color-yellow-50: #fcfde9;
$primitives-color-yellow-500: #e1cc11;
$primitives-color-yellow-600: #c2a10c;
$primitives-color-yellow-700: #9b750d;
$primitives-color-yellow-800: #805d13;
$primitives-color-yellow-900: #6d4b16;
$primitives-color-yellow-950: #3f2809;

/******** Light Theme Colors ********/
/*Common*/
$tokens-colors-light-common-blue-hight: $primitives-color-blue-600;
$tokens-colors-light-common-blue-highter: $primitives-color-blue-700;
$tokens-colors-light-common-blue-hightest: $primitives-color-blue-900-p;
$tokens-colors-light-common-blue-low: $primitives-color-blue-300;
$tokens-colors-light-common-blue-lower: $primitives-color-blue-100;
$tokens-colors-light-common-blue-lowest: $primitives-color-blue-50;
$tokens-colors-light-common-blue-medium: $primitives-color-blue-400;
$tokens-colors-light-common-blur-lower: $primitives-color-blue-100;
$tokens-colors-light-common-blur-yellow-blur: $primitives-color-yellow-300;
$tokens-colors-light-common-danger-hight: $primitives-color-red-600-p;
$tokens-colors-light-common-danger-highter: $primitives-color-red-800;
$tokens-colors-light-common-danger-hightest: $primitives-color-red-950;
$tokens-colors-light-common-danger-low: $primitives-color-red-300;
$tokens-colors-light-common-danger-lower: $primitives-color-red-100;
$tokens-colors-light-common-danger-lowest: $primitives-color-red-50;
$tokens-colors-light-common-danger-medium: $primitives-color-red-400;
$tokens-colors-light-common-focus-effect-focus-line: $primitives-color-purple-purple;
$tokens-colors-light-common-neutral-hight: $primitives-color-slate-600;
$tokens-colors-light-common-neutral-highter: $primitives-color-slate-700;
$tokens-colors-light-common-neutral-hightest: $primitives-color-black-900-p;
$tokens-colors-light-common-neutral-low: $primitives-color-slate-300;
$tokens-colors-light-common-neutral-lower: $primitives-color-slate-100;
$tokens-colors-light-common-neutral-lowest: $primitives-color-white-white;
$tokens-colors-light-common-neutral-medium: $primitives-color-slate-400;
$tokens-colors-light-common-primary-brand-hight: $primitives-color-black-700;
$tokens-colors-light-common-primary-brand-highter: $primitives-color-black-900-p;
$tokens-colors-light-common-primary-brand-hightest: $primitives-color-black-950;
$tokens-colors-light-common-primary-brand-low: $primitives-color-black-300;


$tokens-colors-light-common-primary-brand-lower: $primitives-color-black-100;
$tokens-colors-light-common-primary-brand-lowest: $primitives-color-white-white;
$tokens-colors-light-common-primary-brand-medium: $primitives-color-black-500;
$tokens-colors-light-common-secondary-brand-hight: $primitives-color-yellow-700;
$tokens-colors-light-common-secondary-brand-highter: $primitives-color-yellow-800;
$tokens-colors-light-common-secondary-brand-hightest: $primitives-color-yellow-900;
$tokens-colors-light-common-secondary-brand-low: $primitives-color-yellow-300;
$tokens-colors-light-common-secondary-brand-lower: $primitives-color-yellow-200-p;
$tokens-colors-light-common-secondary-brand-lowest: $primitives-color-yellow-50;
$tokens-colors-light-common-secondary-brand-medium: $primitives-color-yellow-500;
$tokens-colors-light-common-success-hight: $primitives-color-green-600;
$tokens-colors-light-common-success-highter: $primitives-color-green-800;
$tokens-colors-light-common-success-hightest: $primitives-color-green-950;
$tokens-colors-light-common-success-low: $primitives-color-green-300;
$tokens-colors-light-common-success-lower: $primitives-color-green-100;
$tokens-colors-light-common-success-lowest: $primitives-color-green-50;
$tokens-colors-light-common-success-medium: $primitives-color-green-400-p;
$tokens-colors-light-common-warning-hight: $primitives-color-orange-700;
$tokens-colors-light-common-warning-highter: $primitives-color-orange-900;
$tokens-colors-light-common-warning-hightest: $primitives-color-orange-950;
$tokens-colors-light-common-warning-low: $primitives-color-orange-300;
$tokens-colors-light-common-warning-lower: $primitives-color-orange-100;
$tokens-colors-light-common-warning-lowest: $primitives-color-orange-50;
$tokens-colors-light-common-warning-medium: $primitives-color-orange-500;

/* Dark Theme Colors */
$tokens-colors-dark-common-blue-hight: $primitives-color-blue-300;
$tokens-colors-dark-common-blue-highter: $primitives-color-blue-100;
$tokens-colors-dark-common-blue-hightest: $primitives-color-blue-50;
$tokens-colors-dark-common-blue-low: $primitives-color-blue-600;
$tokens-colors-dark-common-blue-lower: $primitives-color-blue-700;
$tokens-colors-dark-common-blue-lowest: $primitives-color-blue-950;
$tokens-colors-dark-common-blue-medium: $primitives-color-blue-400;
$tokens-colors-dark-common-blur-lower: $primitives-color-red-600-p;
$tokens-colors-dark-common-blur-yellow-blur: $primitives-color-blue-500;
$tokens-colors-dark-common-danger-hight: $primitives-color-red-300;
$tokens-colors-dark-common-danger-highter: $primitives-color-red-100;
$tokens-colors-dark-common-danger-hightest: $primitives-color-red-50;
$tokens-colors-dark-common-danger-low: $primitives-color-red-600-p;
$tokens-colors-dark-common-danger-lower: $primitives-color-slate-700;
$tokens-colors-dark-common-danger-lowest: $primitives-color-red-950;
$tokens-colors-dark-common-danger-medium: $primitives-color-red-400;
$tokens-colors-dark-common-focus-effect-focus-line: $primitives-color-purple-purple;
$tokens-colors-dark-common-neutral-hight: $primitives-color-slate-300;
$tokens-colors-dark-common-neutral-highter: $primitives-color-slate-100;
$tokens-colors-dark-common-neutral-hightest: $primitives-color-white-white;
$tokens-colors-dark-common-neutral-low: $primitives-color-slate-600;
$tokens-colors-dark-common-neutral-lower: $primitives-color-slate-700;
$tokens-colors-dark-common-neutral-lowest: $primitives-color-black-900-p;
$tokens-colors-dark-common-neutral-medium: $primitives-color-slate-400;
$tokens-colors-dark-common-primary-brand-hight: $primitives-color-black-200;
$tokens-colors-dark-common-primary-brand-highter: $primitives-color-black-100;
$tokens-colors-dark-common-primary-brand-hightest: $primitives-color-white-white;
$tokens-colors-dark-common-primary-brand-low: $primitives-color-black-600;
$tokens-colors-dark-common-primary-brand-lower: $primitives-color-black-800;
$tokens-colors-dark-common-primary-brand-lowest: $primitives-color-black-900-p;
$tokens-colors-dark-common-primary-brand-medium: $primitives-color-black-400;
$tokens-colors-dark-common-secondary-brand-hight: $primitives-color-yellow-400;
$tokens-colors-dark-common-secondary-brand-highter: $primitives-color-yellow-200-p;
$tokens-colors-dark-common-secondary-brand-hightest: $primitives-color-yellow-50;
$tokens-colors-dark-common-secondary-brand-low: $primitives-color-yellow-700;
$tokens-colors-dark-common-secondary-brand-lower: $primitives-color-yellow-800;
$tokens-colors-dark-common-secondary-brand-lowest: $primitives-color-yellow-950;
$tokens-colors-dark-common-secondary-brand-medium: $primitives-color-yellow-600;
$tokens-colors-dark-common-success-hight: $primitives-color-green-300;
$tokens-colors-dark-common-success-highter: $primitives-color-green-100;
$tokens-colors-dark-common-success-hightest: $primitives-color-green-50;
$tokens-colors-dark-common-success-low: $primitives-color-green-600;
$tokens-colors-dark-common-success-lower: $primitives-color-slate-700;
$tokens-colors-dark-common-success-lowest: $primitives-color-green-950;
$tokens-colors-dark-common-success-medium: $primitives-color-green-500;
$tokens-colors-dark-common-warning-hight: $primitives-color-orange-300;
$tokens-colors-dark-common-warning-highter: $primitives-color-orange-100;
$tokens-colors-dark-common-warning-hightest: $primitives-color-orange-50;
$tokens-colors-dark-common-warning-low: $primitives-color-orange-700;
$tokens-colors-dark-common-warning-lower: $primitives-color-orange-900;
$tokens-colors-dark-common-warning-lowest: $primitives-color-orange-950;
$tokens-colors-dark-common-warning-medium: $primitives-color-orange-500;


/* Light Theme Colors */
$tokens-colors-light-button-error-disable-background: $tokens-colors-light-common-danger-lower;
$tokens-colors-light-button-error-disable-border: $tokens-colors-light-common-danger-lower;
$tokens-colors-light-button-error-disable-icons: $tokens-colors-light-common-danger-medium;
$tokens-colors-light-button-error-disable-text: $tokens-colors-light-common-danger-medium;
$tokens-colors-light-button-error-focused-background: $tokens-colors-light-common-danger-highter;
$tokens-colors-light-button-error-focused-border: $tokens-colors-light-common-danger-highter;
$tokens-colors-light-button-error-focused-icons: $tokens-colors-light-common-danger-lowest;
$tokens-colors-light-button-error-focused-text: $tokens-colors-light-common-danger-lowest;
$tokens-colors-light-button-error-hover-background: $tokens-colors-light-common-danger-hight;
$tokens-colors-light-button-error-hover-border: $tokens-colors-light-common-danger-hight;
$tokens-colors-light-button-error-hover-icons: $tokens-colors-light-common-danger-lowest;
$tokens-colors-light-button-error-hover-text: $tokens-colors-light-common-danger-lowest;
$tokens-colors-light-button-error-pressed-background: $tokens-colors-light-common-danger-hightest;
$tokens-colors-light-button-error-pressed-border: $tokens-colors-light-common-danger-hightest;
$tokens-colors-light-button-error-pressed-icons: $tokens-colors-light-common-danger-low;
$tokens-colors-light-button-error-pressed-text: $tokens-colors-light-common-danger-low;
$tokens-colors-light-button-error-regular-background: $tokens-colors-light-common-danger-highter;
$tokens-colors-light-button-error-regular-border: $tokens-colors-light-common-danger-highter;
$tokens-colors-light-button-error-regular-icons: $tokens-colors-light-common-danger-lowest;
$tokens-colors-light-button-error-regular-text: $tokens-colors-light-common-danger-lowest;

/* Ghost Buttons */
$tokens-colors-light-button-ghost-disable-icons: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-ghost-disable-text: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-button-ghost-focused-icons: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-ghost-focused-text: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-button-ghost-hover-icons: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-ghost-hover-text: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-button-ghost-pressed-icons: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-ghost-pressed-text: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-button-ghost-regular-icons: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-ghost-regular-text: $tokens-colors-light-common-primary-brand-medium;

/* Primary Buttons */
$tokens-colors-light-button-primary-disable-background: $tokens-colors-light-common-primary-brand-lower;
$tokens-colors-light-button-primary-disable-border: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-disable-icons: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-disable-text: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-button-primary-foccused-background: $tokens-colors-light-common-primary-brand-highter;
$tokens-colors-light-button-primary-foccused-border: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-foccused-icons: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-foccused-text: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-button-primary-hover-background: $tokens-colors-light-common-primary-brand-hight;
$tokens-colors-light-button-primary-hover-border: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-hover-icons: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-hover-text: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-button-primary-pressed-background: $tokens-colors-light-common-primary-brand-hightest;
$tokens-colors-light-button-primary-pressed-border: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-pressed-icons: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-pressed-text: $tokens-colors-light-common-primary-brand-low;
$tokens-colors-light-button-primary-regular-background: $tokens-colors-light-common-primary-brand-highter;
$tokens-colors-light-button-primary-regular-border: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-regular-icons: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-button-primary-regular-text: $tokens-colors-light-common-primary-brand-lowest;

/* Secondary Buttons */
$tokens-colors-light-button-secondary-disable-background: $tokens-colors-light-common-primary-brand-lower;
$tokens-colors-light-button-secondary-disable-border: $tokens-colors-light-common-neutral-low;
$tokens-colors-light-button-secondary-disable-icons: $tokens-colors-light-common-neutral-low;
$tokens-colors-light-button-secondary-disable-text: $tokens-colors-light-common-primary-brand-low;
$tokens-colors-light-button-secondary-focused-background: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-button-secondary-focused-border: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-button-secondary-focused-icons: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-button-secondary-focused-text: $tokens-colors-light-common-primary-brand-highter;
$tokens-colors-light-button-secondary-hover-background: $tokens-colors-light-common-primary-brand-lower;
$tokens-colors-light-button-secondary-hover-border: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-button-secondary-hover-icons: $tokens-colors-light-common-neutral-hight;
$tokens-colors-light-button-secondary-hover-text: $tokens-colors-light-common-primary-brand-hight;
$tokens-colors-light-button-secondary-pressed-background: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-button-secondary-pressed-border: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-secondary-pressed-icons: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-button-secondary-pressed-text: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-button-secondary-regular-background: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-button-secondary-regular-border: $tokens-colors-light-common-neutral-hightest;
$tokens-colors-light-button-secondary-regular-icons: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-button-secondary-regular-text: $tokens-colors-light-common-primary-brand-highter;

/* Success Buttons */
$tokens-colors-light-button-success-disable-background: $tokens-colors-light-common-success-lower;
$tokens-colors-light-button-success-disable-border: $tokens-colors-light-common-success-lower;
$tokens-colors-light-button-success-disable-icons: $tokens-colors-light-common-success-medium;
$tokens-colors-light-button-success-disable-text: $tokens-colors-light-common-success-medium;
$tokens-colors-light-button-success-focused-background: $tokens-colors-light-common-success-highter;
$tokens-colors-light-button-success-focused-border: $tokens-colors-light-common-success-highter;
$tokens-colors-light-button-success-focused-icons: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-button-success-focused-text: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-button-success-hover-background: $tokens-colors-light-common-success-hight;
$tokens-colors-light-button-success-hover-border: $tokens-colors-light-common-success-hight;
$tokens-colors-light-button-success-hover-icons: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-button-success-hover-text: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-button-success-pressed-background: $tokens-colors-light-common-success-hightest;
$tokens-colors-light-button-success-pressed-border: $tokens-colors-light-common-success-hightest;
$tokens-colors-light-button-success-pressed-icons: $tokens-colors-light-common-success-low;
$tokens-colors-light-button-success-pressed-text: $tokens-colors-light-common-success-low;
$tokens-colors-light-button-success-regular-background: $tokens-colors-light-common-success-highter;
$tokens-colors-light-button-success-regular-border: $tokens-colors-light-common-success-highter;
$tokens-colors-light-button-success-regular-icons: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-button-success-regular-text: $tokens-colors-light-common-success-lowest;

/* Warning Buttons */
$tokens-colors-light-button-warning-disable-background: $tokens-colors-light-common-warning-lower;
$tokens-colors-light-button-warning-disable-border: $tokens-colors-light-common-warning-lower;
$tokens-colors-light-button-warning-disable-icons: $tokens-colors-light-common-warning-medium;
$tokens-colors-light-button-warning-disable-text: $tokens-colors-light-common-warning-medium;
$tokens-colors-light-button-warning-focused-background: $tokens-colors-light-common-warning-highter;
$tokens-colors-light-button-warning-focused-border: $tokens-colors-light-common-warning-highter;
$tokens-colors-light-button-warning-focused-icons: $tokens-colors-light-common-warning-lowest;
$tokens-colors-light-button-warning-focused-text: $tokens-colors-light-common-warning-lowest;
$tokens-colors-light-button-warning-hover-background: $tokens-colors-light-common-warning-hight;
$tokens-colors-light-button-warning-hover-border: $tokens-colors-light-common-warning-hight;
$tokens-colors-light-button-warning-hover-icons: $tokens-colors-light-common-warning-lowest;
$tokens-colors-light-button-warning-hover-text: $tokens-colors-light-common-warning-lowest;
$tokens-colors-light-button-warning-pressed-background: $tokens-colors-light-common-warning-hightest;
$tokens-colors-light-button-warning-pressed-border: $tokens-colors-light-common-warning-hightest;
$tokens-colors-light-button-warning-pressed-icons: $tokens-colors-light-common-warning-low;
$tokens-colors-light-button-warning-pressed-text: $tokens-colors-light-common-warning-low;
$tokens-colors-light-button-warning-regular-background: $tokens-colors-light-common-warning-highter;
$tokens-colors-light-button-warning-regular-border: $tokens-colors-light-common-warning-highter;
$tokens-colors-light-button-warning-regular-icons: $tokens-colors-light-common-warning-lowest;
$tokens-colors-light-button-warning-regular-text: $tokens-colors-light-common-warning-lowest;

/* Success Buttons */
$tokens-colors-light-input-primary-success-background: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-input-primary-success-border: $tokens-colors-light-common-success-low;
$tokens-colors-light-input-primary-success-icon: $tokens-colors-light-common-success-medium;
$tokens-colors-light-input-primary-success-label: $tokens-colors-light-common-primary-brand-hightest;
$tokens-colors-light-input-primary-success-placeholder: $tokens-colors-light-common-success-hight;
/******** Dark Theme Colors ********/
/*Success Buttons */
$tokens-colors-dark-input-primary-success-background: $tokens-colors-light-common-success-lowest;
$tokens-colors-dark-input-primary-success-border: $tokens-colors-light-common-success-low;
$tokens-colors-dark-input-primary-success-icon: $tokens-colors-light-common-success-medium;
$tokens-colors-dark-input-primary-success-label: $tokens-colors-light-common-success-medium;
$tokens-colors-dark-input-primary-success-placeholder: $tokens-colors-light-common-success-medium;
/* Error Buttons */

$tokens-colors-dark-button-error-disable-background: $tokens-colors-dark-common-danger-medium;
$tokens-colors-dark-button-error-disable-border: $tokens-colors-dark-common-danger-medium;
$tokens-colors-dark-button-error-disable-icons: $tokens-colors-dark-common-danger-lower;
$tokens-colors-dark-button-error-disable-text: $tokens-colors-dark-common-danger-lower;
$tokens-colors-dark-button-error-focused-background: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-button-error-focused-border: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-button-error-focused-icons: $tokens-colors-dark-common-danger-highter;
$tokens-colors-dark-button-error-focused-text: $tokens-colors-dark-common-danger-highter;
$tokens-colors-dark-button-error-hover-background: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-button-error-hover-border: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-button-error-hover-icons: $tokens-colors-dark-common-danger-hight;
$tokens-colors-dark-button-error-hover-text: $tokens-colors-dark-common-danger-hight;
$tokens-colors-dark-button-error-pressed-background: $tokens-colors-dark-common-danger-low;
$tokens-colors-dark-button-error-pressed-border: $tokens-colors-dark-common-danger-low;
$tokens-colors-dark-button-error-pressed-icons: $tokens-colors-dark-common-danger-hightest;
$tokens-colors-dark-button-error-pressed-text: $tokens-colors-dark-common-danger-hightest;
$tokens-colors-dark-button-error-regular-background: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-button-error-regular-border: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-button-error-regular-icons: $tokens-colors-dark-common-danger-highter;
$tokens-colors-dark-button-error-regular-text: $tokens-colors-dark-common-danger-highter;

/* Ghost Buttons */
$tokens-colors-dark-button-ghost-disable-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-disable-text: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-focused-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-focused-text: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-hover-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-hover-text: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-pressed-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-pressed-text: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-regular-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-ghost-regular-text: $tokens-colors-dark-common-primary-brand-medium;

/* Primary Buttons */
$tokens-colors-dark-button-primary-disable-background: $tokens-colors-dark-common-primary-brand-low;
$tokens-colors-dark-button-primary-disable-border: $tokens-colors-dark-common-primary-brand-low;
$tokens-colors-dark-button-primary-disable-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-primary-disable-text: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-primary-foccused-background: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-primary-foccused-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-primary-foccused-icons: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-button-primary-foccused-text: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-button-primary-hover-background: $tokens-colors-dark-common-primary-brand-hight;
$tokens-colors-dark-button-primary-hover-border: $tokens-colors-dark-common-primary-brand-highter;
$tokens-colors-dark-button-primary-hover-icons: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-button-primary-hover-text: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-button-primary-pressed-background: $tokens-colors-dark-common-primary-brand-hight;
$tokens-colors-dark-button-primary-pressed-border: $tokens-colors-dark-common-primary-brand-lower;
$tokens-colors-dark-button-primary-pressed-icons: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-primary-pressed-text: $tokens-colors-dark-common-primary-brand-medium;
$tokens-colors-dark-button-primary-regular-background: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-primary-regular-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-primary-regular-icons: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-button-primary-regular-text: $tokens-colors-dark-common-primary-brand-lowest;

/* Secondary Buttons */
$tokens-colors-dark-button-secondary-disable-background: $tokens-colors-dark-common-primary-brand-lower;
$tokens-colors-dark-button-secondary-disable-border: $tokens-colors-dark-common-primary-brand-low;
$tokens-colors-dark-button-secondary-disable-icons: $tokens-colors-dark-common-primary-brand-low;
$tokens-colors-dark-button-secondary-disable-text: $tokens-colors-dark-common-primary-brand-low;
$tokens-colors-dark-button-secondary-focused-background: $tokens-colors-dark-common-primary-brand-lower;
$tokens-colors-dark-button-secondary-focused-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-focused-icons: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-focused-text: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-hover-background: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-button-secondary-hover-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-hover-icons: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-hover-text: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-pressed-background: $tokens-colors-dark-common-primary-brand-low;
$tokens-colors-dark-button-secondary-pressed-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-pressed-icons: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-pressed-text: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-regular-background: $tokens-colors-dark-common-primary-brand-lower;
$tokens-colors-dark-button-secondary-regular-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-regular-icons: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-button-secondary-regular-text: $tokens-colors-dark-common-primary-brand-hightest;

/* Success Buttons */
$tokens-colors-dark-button-success-disable-background: $tokens-colors-dark-common-success-medium;
$tokens-colors-dark-button-success-disable-border: $tokens-colors-dark-common-success-medium;
$tokens-colors-dark-button-success-disable-icons: $tokens-colors-dark-common-success-lower;
$tokens-colors-dark-button-success-disable-text: $tokens-colors-dark-common-success-lower;
$tokens-colors-dark-button-success-focused-background: $tokens-colors-dark-common-success-highter;
$tokens-colors-dark-button-success-focused-border: $tokens-colors-dark-common-success-highter;
$tokens-colors-dark-button-success-focused-icons: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-button-success-focused-text: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-button-success-hover-background: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-button-success-hover-border: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-button-success-hover-icons: $tokens-colors-dark-common-success-hight;
$tokens-colors-dark-button-success-hover-text: $tokens-colors-dark-common-success-hight;
$tokens-colors-dark-button-success-pressed-background: $tokens-colors-dark-common-success-low;
$tokens-colors-dark-button-success-pressed-border: $tokens-colors-dark-common-success-low;
$tokens-colors-dark-button-success-pressed-icons: $tokens-colors-dark-common-success-hightest;
$tokens-colors-dark-button-success-pressed-text: $tokens-colors-dark-common-success-hightest;
$tokens-colors-dark-button-success-regular-background: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-button-success-regular-border: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-button-success-regular-icons: $tokens-colors-dark-common-success-highter;
$tokens-colors-dark-button-success-regular-text: $tokens-colors-dark-common-success-highter;

/* Warning Buttons */
$tokens-colors-dark-button-warning-disable-background: $tokens-colors-dark-common-warning-medium;
$tokens-colors-dark-button-warning-disable-border: $tokens-colors-dark-common-warning-medium;
$tokens-colors-dark-button-warning-disable-icons: $tokens-colors-dark-common-warning-lower;
$tokens-colors-dark-button-warning-disable-text: $tokens-colors-dark-common-warning-lower;
$tokens-colors-dark-button-warning-focused-background: $tokens-colors-dark-common-warning-highter;
$tokens-colors-dark-button-warning-focused-border: $tokens-colors-dark-common-warning-highter;
$tokens-colors-dark-button-warning-focused-icons: $tokens-colors-dark-common-warning-lowest;
$tokens-colors-dark-button-warning-focused-text: $tokens-colors-dark-common-warning-lowest;
$tokens-colors-dark-button-warning-hover-background: $tokens-colors-dark-common-warning-lowest;
$tokens-colors-dark-button-warning-hover-border: $tokens-colors-dark-common-warning-lowest;
$tokens-colors-dark-button-warning-hover-icons: $tokens-colors-dark-common-warning-hight;
$tokens-colors-dark-button-warning-hover-text: $tokens-colors-dark-common-warning-hight;
$tokens-colors-dark-button-warning-pressed-background: $tokens-colors-dark-common-warning-low;
$tokens-colors-dark-button-warning-pressed-border: $tokens-colors-dark-common-warning-low;
$tokens-colors-dark-button-warning-pressed-icons: $tokens-colors-dark-common-warning-hightest;
$tokens-colors-dark-button-warning-pressed-text: $tokens-colors-dark-common-warning-hightest;
$tokens-colors-dark-button-warning-regular-background: $tokens-colors-dark-common-warning-lowest;
$tokens-colors-dark-button-warning-regular-border: $tokens-colors-dark-common-warning-highter;
$tokens-colors-dark-button-warning-regular-icons: $tokens-colors-dark-common-warning-highter;
$tokens-colors-dark-button-warning-regular-text: $tokens-colors-dark-common-warning-highter;

/* Surface Light Theme Colors */
$tokens-colors-light-surface-action-1: #f9fafb00;
$tokens-colors-light-surface-action-2: #f9fafb00;
$tokens-colors-light-surface-action-2-hover: #f9fafb00;
$tokens-colors-light-surface-alert-error: #f9fafb00;
$tokens-colors-light-surface-alert-information: #f9fafb00;
$tokens-colors-light-surface-alert-success: #f9fafb00;
$tokens-colors-light-surface-alert-warning: #f9fafb00;
$tokens-colors-light-surface-disabled: #f9fafb00;
$tokens-colors-light-surface-disabled-selected: #f9fafb00;
$tokens-colors-light-surface-erro: #f9fafb00;
$tokens-colors-light-surface-highlight: #f9fafb00;
$tokens-colors-light-surface-information: #f9fafb00;
$tokens-colors-light-surface-modal: #f9fafb00;
$tokens-colors-light-surface-primary: #ffffff4c;
$tokens-colors-light-surface-success: #f9fafb00;
/*Surface Dark Theme Colors*/
$tokens-colors-dark-surface-action-1: #18181b00;
$tokens-colors-dark-surface-action-2: #18181b00;
$tokens-colors-dark-surface-action-2-hover: #18181b00;
$tokens-colors-dark-surface-alert-error: #18181b00;
$tokens-colors-dark-surface-alert-information: #18181b00;
$tokens-colors-dark-surface-alert-success: #18181b00;
$tokens-colors-dark-surface-alert-warning: #18181b00;
$tokens-colors-dark-surface-disabled: #18181b00;
$tokens-colors-dark-surface-disabled-selected: #18181b00;
$tokens-colors-dark-surface-erro: #18181b00;
$tokens-colors-dark-surface-highlight: #18181b00;
$tokens-colors-dark-surface-information: #18181b00;
$tokens-colors-dark-surface-modal: #18181b00;
$tokens-colors-dark-surface-primary: #27272a4c;
$tokens-colors-dark-surface-success: #18181b00;


//Primitives Spacing
$primitives-spacing-0-px: 0px;
$primitives-spacing-1-px: 1px;
$primitives-spacing-10-px: 10px;
$primitives-spacing-112-px: 112px;
$primitives-spacing-12-px: 12px;
$primitives-spacing-14-px: 14px;
$primitives-spacing-16-px: 16px;
$primitives-spacing-18-px: 18px;
$primitives-spacing-2-px: 2px;
$primitives-spacing-20-px: 20px;
$primitives-spacing-24-px: 24px;
$primitives-spacing-28-px: 28px;
$primitives-spacing-32-px: 32px;
$primitives-spacing-36-px: 36px;
$primitives-spacing-4-px: 4px;
$primitives-spacing-40-px: 40px;
$primitives-spacing-44-px: 44px;
$primitives-spacing-48-px: 48px;
$primitives-spacing-56-px: 56px;
$primitives-spacing-6-px: 6px;
$primitives-spacing-64-px: 64px;
$primitives-spacing-8-px: 8px;
$primitives-spacing-80-px: 80px;
$primitives-spacing-96-px: 96px;
//Typography
$typography-web-1920-font-size-10: 10px;
$typography-web-1728-font-size-10: 10px;
$typography-mobile-font-size-10: 10px;
$typography-web-1920-font-size-12: 12px;
$typography-web-1728-font-size-12: 12px;
$typography-mobile-font-size-12: 12px;
$typography-web-1920-font-size-14: 14px;
$typography-web-1728-font-size-14: 14px;
$typography-mobile-font-size-14: 14px;
$typography-web-1920-font-size-16: 16px;
$typography-web-1728-font-size-16: 16px;
$typography-mobile-font-size-16: 16px;
$typography-web-1920-font-size-18: 18px;
$typography-web-1728-font-size-18: 18px;
$typography-mobile-font-size-18: 18px;
$typography-web-1920-font-size-20: 20px;
$typography-web-1728-font-size-20: 20px;
$typography-mobile-font-size-20: 20px;
$typography-web-1920-font-size-22: 22px;
$typography-web-1728-font-size-22: 22px;
$typography-mobile-font-size-22: 22px;
$typography-web-1920-font-size-24: 24px;
$typography-web-1728-font-size-24: 24px;
$typography-mobile-font-size-24: 24px;
$typography-web-1920-font-size-26: 26px;
$typography-web-1728-font-size-26: 26px;
$typography-mobile-font-size-26: 26px;
$typography-web-1920-font-size-28: 28px;
$typography-web-1728-font-size-28: 28px;
$typography-mobile-font-size-28: 28px;
$typography-web-1920-font-size-30: 30px;
$typography-web-1728-font-size-30: 30px;
$typography-mobile-font-size-30: 30px;
$typography-web-1920-font-size-32: 32px;
$typography-web-1728-font-size-32: 32px;
$typography-mobile-font-size-32: 32px;
$typography-web-1920-font-size-34: 34px;
$typography-web-1728-font-size-34: 34px;
$typography-mobile-font-size-34: 34px;
$typography-web-1920-font-size-36: 36px;
$typography-web-1728-font-size-36: 36px;
$typography-mobile-font-size-36: 36px;
$typography-web-1920-font-size-38: 38px;
$typography-web-1728-font-size-38: 38px;
$typography-mobile-font-size-38: 38px;
$typography-web-1920-font-size-40: 40px;
$typography-web-1728-font-size-40: 40px;
$typography-mobile-font-size-40: 40px;
$typography-web-1920-font-size-42: 42px;
$typography-web-1728-font-size-42: 42px;
$typography-mobile-font-size-42: 42px;
$typography-web-1920-font-size-44: 44px;
$typography-web-1728-font-size-44: 44px;
$typography-mobile-font-size-44: 44px;
$typography-web-1920-font-size-46: 46px;
$typography-web-1728-font-size-46: 46px;
$typography-mobile-font-size-46: 46px;
$typography-web-1920-font-size-48: 48px;
$typography-web-1728-font-size-48: 48px;
$typography-mobile-font-size-48: 48px;
$typography-web-1920-font-size-50: 50px;
$typography-web-1728-font-size-50: 50px;
$typography-mobile-font-size-50: 50px;
$typography-web-1920-font-size-52: 52px;
$typography-web-1728-font-size-52: 52px;
$typography-mobile-font-size-52: 52px;
$typography-web-1920-font-size-54: 54px;
$typography-web-1728-font-size-54: 54px;
$typography-mobile-font-size-54: 54px;
$typography-web-1920-font-size-56: 1656px;
$typography-web-1728-font-size-56: 1656px;
$typography-mobile-font-size-56: 1656px;
$typography-web-1920-font-size-58: 58px;
$typography-web-1728-font-size-58: 58px;
$typography-mobile-font-size-58: 58px;
$typography-web-1920-font-size-60: 60px;
$typography-web-1728-font-size-60: 60px;
$typography-mobile-font-size-60: 60px;
$typography-web-1920-font-size-62: 62px;
$typography-web-1728-font-size-62: 62px;
$typography-mobile-font-size-62: 62px;
$typography-web-1920-font-size-64: 64px;
$typography-web-1728-font-size-64: 64px;
$typography-mobile-font-size-64: 64px;
$typography-web-1920-font-size-66: 66px;
$typography-web-1728-font-size-66: 66px;
$typography-mobile-font-size-66: 66px;
$typography-web-1920-font-size-68: 68px;
$typography-web-1728-font-size-68: 68px;
$typography-mobile-font-size-68: 68px;
$typography-web-1920-font-size-70: 70px;
$typography-web-1728-font-size-70: 70px;
$typography-mobile-font-size-70: 70px;
$typography-web-1920-font-size-72: 72px;
$typography-web-1728-font-size-72: 72px;
$typography-mobile-font-size-72: 72px;
//Primitive Border Radius
$primitives-border-radius-rounded-full: 9999px;

//Primitives Border Radius
$primitives-border-radius-rounded-2-xl-16-px: $primitives-spacing-16-px;
$primitives-border-radius-rounded-2-xxl-28-px: $primitives-spacing-28-px;
$primitives-border-radius-rounded-3-xl-24-px: $primitives-spacing-24-px;
$primitives-border-radius-rounded-4-px: $primitives-spacing-4-px;
$primitives-border-radius-rounded-4-xl-32-px: $typography-web-1920-font-size-32;
$primitives-border-radius-rounded-lg-8-px: $primitives-spacing-8-px;
$primitives-border-radius-rounded-md-6-px: $primitives-spacing-6-px;
$primitives-border-radius-rounded-none: $primitives-spacing-0-px;
$primitives-border-radius-rounded-sm-2-px: $primitives-spacing-2-px;
$primitives-border-radius-rounded-xl-12-px: $primitives-spacing-12-px;
//Common screen Radius
$tokens-screen-common-radius-l: $primitives-border-radius-rounded-xl-12-px;
$tokens-screen-common-radius-m: $primitives-border-radius-rounded-lg-8-px;
$tokens-screen-common-radius-s-4-px: $primitives-border-radius-rounded-4-px;
$tokens-screen-common-radius-square: $primitives-border-radius-rounded-none;
$tokens-screen-common-radius-xl: $primitives-border-radius-rounded-2-xl-16-px;
$tokens-screen-common-radius-xxl: $primitives-border-radius-rounded-3-xl-24-px;
$tokens-screen-common-radius-xxxl: $primitives-border-radius-rounded-2-xxl-28-px;
// Common Screen
$tokens-screen-common-radius-round: $primitives-border-radius-rounded-full;
$tokens-screen-common-spacing-3-xl-44-px: $primitives-spacing-44-px;
$tokens-screen-common-spacing-3-xs-2-px: $primitives-spacing-2-px;
$tokens-screen-common-spacing-4-xl-56-px: $primitives-spacing-56-px;
$tokens-screen-common-spacing-l-16-px: $primitives-spacing-16-px;
$tokens-screen-common-spacing-m-12-px: $primitives-spacing-12-px;
$tokens-screen-common-spacing-s-8-px: $primitives-spacing-8-px;
$tokens-screen-common-spacing-xl-24-px: $primitives-spacing-24-px;
$tokens-screen-common-spacing-xs-6-px: $primitives-spacing-6-px;
$tokens-screen-common-spacing-xxl-32-px: $primitives-spacing-32-px;
$tokens-screen-common-spacing-xxs-4-px: $primitives-spacing-4-px;
//Screen Size
$tokens-screen-size-button-large-gap: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-button-large-icon-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-button-large-padding-h: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-button-large-padding-v: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-button-large-radius: $tokens-screen-common-radius-l;
$tokens-screen-size-button-large-text-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-button-larger-gap: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-button-larger-icon-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-button-larger-padding-h: $tokens-screen-common-spacing-xxl-32-px;
$tokens-screen-size-button-larger-padding-v: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-button-larger-radius: $tokens-screen-common-radius-xl;
$tokens-screen-size-button-larger-text-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-button-medium-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-button-medium-icon-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-button-medium-padding-h: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-button-medium-padding-v: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-button-medium-radius: $tokens-screen-common-radius-m;
$tokens-screen-size-button-medium-text-size: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-button-small-gap: $tokens-screen-common-spacing-xxs-4-px;
$tokens-screen-size-button-small-icon-size: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-button-small-padding-h: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-button-small-padding-v: $tokens-screen-common-spacing-xxs-4-px;
$tokens-screen-size-button-small-radius: $tokens-screen-common-radius-s-4-px;
$tokens-screen-size-button-small-text-size: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-large-gap: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-input-large-icon-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-input-large-left-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-large-line-spacing: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-large-padding-h: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-large-padding-v: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-input-large-radius: $tokens-screen-common-radius-l;
$tokens-screen-size-input-large-right-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-larger-gap: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-input-larger-icon-size: $tokens-screen-common-spacing-xl-24-px;
$tokens-screen-size-input-larger-left-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-larger-line-spacing: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-larger-padding-h: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-larger-padding-v: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-larger-radius: $tokens-screen-common-radius-xl;
$tokens-screen-size-input-larger-right-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-medium-gap: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-input-medium-icon-size: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-medium-left-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-medium-line-spacing: $tokens-screen-common-spacing-xs-6-px;
$tokens-screen-size-input-medium-padding-h: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-medium-padding-v: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-medium-radius: $tokens-screen-common-radius-l;
$tokens-screen-size-input-medium-right-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-small-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-small-icon-size: $tokens-screen-common-spacing-l-16-px;
$tokens-screen-size-input-small-left-content-gap: $tokens-screen-common-spacing-s-8-px;
$tokens-screen-size-input-small-line-spacing: $tokens-screen-common-spacing-xxs-4-px;
$tokens-screen-size-input-small-padding-h: $tokens-screen-common-spacing-m-12-px;
$tokens-screen-size-input-small-padding-v: $tokens-screen-common-spacing-xs-6-px;
$tokens-screen-size-input-small-radius: $tokens-screen-common-radius-m;
$tokens-screen-size-input-small-right-content-gap: $tokens-screen-common-spacing-s-8-px;


//input light theme
$tokens-colors-light-input-primary-active-focused-background: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-input-primary-active-focused-border: $tokens-colors-light-common-focus-effect-focus-line;
$tokens-colors-light-input-primary-active-focused-icons: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-input-primary-active-focused-label: $tokens-colors-light-common-neutral-hightest;
$tokens-colors-light-input-primary-active-focused-placeholder: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-input-primary-disable-background: $tokens-colors-light-common-primary-brand-lower;
$tokens-colors-light-input-primary-disable-border: $tokens-colors-light-common-primary-brand-lower;
$tokens-colors-light-input-primary-disable-icon: $tokens-colors-light-common-primary-brand-low;
$tokens-colors-light-input-primary-disable-label: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-input-primary-disable-placeholder: $tokens-colors-light-common-primary-brand-low;
$tokens-colors-light-input-primary-error-background: $tokens-colors-light-common-danger-lowest;
$tokens-colors-light-input-primary-error-border: $tokens-colors-light-common-danger-low;
$tokens-colors-light-input-primary-error-icon: $tokens-colors-light-common-danger-medium;
$tokens-colors-light-input-primary-error-label: $tokens-colors-light-common-primary-brand-hightest;
$tokens-colors-light-input-primary-error-placeholder: $tokens-colors-light-common-danger-medium;
$tokens-colors-light-input-primary-filled-background: $tokens-colors-light-common-neutral-lowest;
$tokens-colors-light-input-primary-filled-border: $tokens-colors-light-common-neutral-hightest;
$tokens-colors-light-input-primary-filled-icon: $tokens-colors-light-common-neutral-highter;
$tokens-colors-light-input-primary-filled-label: $tokens-colors-light-common-neutral-hightest;
$tokens-colors-light-input-primary-filled-placeholder: $tokens-colors-light-common-neutral-hightest;
$tokens-colors-light-input-primary-hover-background: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-input-primary-hover-border: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-input-primary-hover-icon: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-input-primary-hover-label: $tokens-colors-light-common-primary-brand-highter;
$tokens-colors-light-input-primary-hover-placeholder: $tokens-colors-light-common-neutral-medium;
$tokens-colors-light-input-primary-regular-background: $tokens-colors-light-common-primary-brand-lowest;
$tokens-colors-light-input-primary-regular-border: $tokens-colors-light-common-primary-brand-medium;
$tokens-colors-light-input-primary-regular-icon: $tokens-colors-light-common-neutral-low;
$tokens-colors-light-input-primary-regular-label: $tokens-colors-light-common-neutral-hightest;
$tokens-colors-light-input-primary-regular-placeholder: $tokens-colors-light-common-neutral-low;
$tokens-colors-light-input-primary-success-background: $tokens-colors-light-common-success-lowest;
$tokens-colors-light-input-primary-success-border: $tokens-colors-light-common-success-low;
$tokens-colors-light-input-primary-success-icon: $tokens-colors-light-common-success-medium;
$tokens-colors-light-input-primary-success-label: $tokens-colors-light-common-primary-brand-hightest;
$tokens-colors-light-input-primary-success-placeholder: $tokens-colors-light-common-success-hight;

//input dark theme
$tokens-colors-dark-input-primary-active-focused-background: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-input-primary-active-focused-border: $tokens-colors-dark-common-focus-effect-focus-line;
$tokens-colors-dark-input-primary-active-focused-icons: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-input-primary-active-focused-label: $tokens-colors-dark-common-neutral-hightest;
$tokens-colors-dark-input-primary-active-focused-placeholder: $tokens-colors-dark-common-neutral-hightest;
$tokens-colors-dark-input-primary-disable-background: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-input-primary-disable-border: $tokens-colors-dark-common-neutral-lower;
$tokens-colors-dark-input-primary-disable-icon: $tokens-colors-dark-common-neutral-low;
$tokens-colors-dark-input-primary-disable-label: $tokens-colors-dark-common-neutral-medium;
$tokens-colors-dark-input-primary-disable-placeholder: $tokens-colors-dark-common-neutral-low;
$tokens-colors-dark-input-primary-error-background: $tokens-colors-dark-common-danger-lowest;
$tokens-colors-dark-input-primary-error-border: $tokens-colors-dark-common-danger-hight;
$tokens-colors-dark-input-primary-error-icon: $tokens-colors-dark-common-danger-medium;
$tokens-colors-dark-input-primary-error-label: $tokens-colors-dark-common-neutral-hightest;
$tokens-colors-dark-input-primary-error-placeholder: $tokens-colors-dark-common-danger-medium;
$tokens-colors-dark-input-primary-filled-placeholder: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-input-primary-filled-background: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-input-primary-filled-border: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-input-primary-filled-icon: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-input-primary-filled-label: $tokens-colors-dark-common-neutral-hightest;
$tokens-colors-dark-input-primary-hover-background: $tokens-colors-dark-common-primary-brand-lowest;
$tokens-colors-dark-input-primary-hover-border: $tokens-colors-dark-common-neutral-hight;
$tokens-colors-dark-input-primary-hover-icon: $tokens-colors-dark-common-neutral-hight;
$tokens-colors-dark-input-primary-hover-label: $tokens-colors-dark-common-neutral-hightest;
$tokens-colors-dark-input-primary-hover-placeholder: $tokens-colors-dark-common-neutral-hight;
$tokens-colors-dark-input-primary-regular-background: $tokens-colors-dark-common-primary-brand-lower;
$tokens-colors-dark-input-primary-regular-border: $tokens-colors-dark-common-neutral-medium;
$tokens-colors-dark-input-primary-regular-icon: $tokens-colors-dark-common-neutral-medium;
$tokens-colors-dark-input-primary-regular-label: $tokens-colors-dark-common-primary-brand-hightest;
$tokens-colors-dark-input-primary-regular-placeholder: $tokens-colors-dark-common-neutral-medium;
$tokens-colors-dark-input-primary-success-background: $tokens-colors-dark-common-success-lowest;
$tokens-colors-dark-input-primary-success-border: $tokens-colors-dark-common-success-low;
$tokens-colors-dark-input-primary-success-icon: $tokens-colors-dark-common-success-medium;
$tokens-colors-dark-input-primary-success-label: $tokens-colors-dark-common-success-medium;
$tokens-colors-dark-input-primary-success-placeholder: $tokens-colors-dark-common-success-medium;


// font size
$tokens-font-size-14: $primitives-spacing-14-px;
$tokens-font-size-16: $primitives-spacing-16-px;
$tokens-font-size-18: $primitives-spacing-18-px;
$tokens-font-size-20: $primitives-spacing-20-px;
$tokens-font-size-28: $primitives-spacing-28-px;
$tokens-font-size-32: $primitives-spacing-32-px;
$tokens-font-size-64: $primitives-spacing-64-px;
$tokens-font-size-96: $primitives-spacing-96-px;
$tokens-font-size-48: $primitives-spacing-48-px;


//Text
$tokens-colors-light-text-body: #ffffff4c;
$tokens-colors-dark-text-body: #27272a4c;
$tokens-colors-light-text-label: #f9fafb00;
$tokens-colors-dark-text-label: #18181b00;
$tokens-colors-light-text-text: #f9fafb00;
$tokens-colors-dark-text-text: #18181b00;

